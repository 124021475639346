import React from "react"
// import { Link } from "gatsby"
import { connect, useSelector } from "react-redux"

import { UserGuideQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import { BreadCrumbBanner, SubscriptionPanel } from "../../components/shared"
// import { IMAGES } from '../../constants';

const Careers = () => {
  const language = useSelector(state => state.language)
  const { guides_box, page_heading, banner_image } = UserGuideQuery(language)
  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />
        <section className="section-padding2 section-bg">
          <div className="card-style team--card3">
            <div className="container py-5">
              <div className="row">
                {guides_box.map((box, boxInd) => (
                  <div className="col-lg-4 col-sm-6" key={'sdfs'+boxInd}>
                    <div className="card card--team3">
                      <a href={box.guide_file.url} target="_blank" rel="noreferrer" >
                        <div className="card__thumbnail">
                          <img src={box.guide_image.url} alt="" />
                        </div>
                      </a>
                      <div className="card-body text-center">
                        <a href={box.guide_image.url} target="_blank" rel="noreferrer"></a>
                        <h6>
                          <a
                            href={box.guide_file.url}
                            target="_blank" rel="noreferrer"
                            className="text-dark"
                          >
                            {box.guide_title[0].text}
                          </a>
                        </h6>
                        <span className="subtitle">
                          {box.guide_description[0].text}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(Careers)
